<template>
	<div>
		<h1 class="tw-mx-auto tw-font-bold tw-text-2xl tw-tracking-tighter ">
			Waiting for approval...
		</h1>
	</div>
</template>

<script>
// import ExtraLayout from '@/layouts/ExtraLayout'
export default {
	name: 'Pending',
	// components: {
	// 	ExtraLayout,
	// },
}
</script>
